import React, { CSSProperties, FC } from 'react';
import styled from 'styled-components';
import { MediaQuery, Spacing } from '../../css-in-js';

interface ApgSectionInterface {
  spacing?: string
}

const ApgSection = styled.div<ApgSectionInterface>`
  min-height: 40rem;
  overflow: hidden;
  padding-top: ${Spacing.Spacing6};
  padding-bottom: ${Spacing.Spacing6};
  position: relative;

  ${({ spacing }) => spacing === 'sm' && `
    padding-top: ${Spacing.Spacing3};
    padding-bottom: ${Spacing.Spacing3};
  `}

  ${({ spacing }) => spacing === 'lg' && `
    padding-top: ${Spacing.Spacing9};
    padding-bottom: ${Spacing.Spacing9};
  `}

  ${({ spacing }) => spacing === 'xl' && `
    padding-top: ${Spacing.Spacing12};
    padding-bottom: ${Spacing.Spacing12};
  `}

  .section-main-container {
    z-index: 2;
  }

  .section-top {
    pointer-events: none;
    left: -1rem;
    position: absolute;
    top: -18rem;
    transform: rotate(-8deg);
    width: 100%;
    z-index: 1;

    ${MediaQuery.isMedium} {
      top: -20rem;
    }

    ${MediaQuery.GreaterThanMedium} {
      top: -22rem;
    }
  }

  .section-bottom {
    pointer-events: none;
    bottom: -18rem;
    left: -1rem;
    position: absolute;
    transform: rotate(8deg);
    width: 100%;
    z-index: 1;

    ${MediaQuery.isMedium} {
      bottom: -20rem;
    }

    ${MediaQuery.GreaterThanMedium} {
      bottom: -22rem;
    }
  }
`;

export interface AppToolbarInterface {
  spacing?: string;
  style?: CSSProperties;
  showVolt?: boolean;
  showVoltTop?: boolean;
  showVoltBottom?: boolean;
  children?: React.ReactNode;
}

const Section: FC<AppToolbarInterface> = ({ spacing, style, showVolt, showVoltTop = true, showVoltBottom = true, children }) => {
  return (
    <ApgSection style={style} spacing={spacing}>
      {showVolt && showVoltTop && (
        <div className="section-top">
          <img src="https://cdn.arenaprogaming.com/static/wild-rift-app/misc-top.png" />
        </div>
      )}
      <div className="section-main-container">
        {children}
      </div>
      {showVolt && showVoltBottom && (
        <div className="section-bottom">
          <img src="https://cdn.arenaprogaming.com/static/wild-rift-app/misc-bottom.png" />
        </div>
      )}
    </ApgSection>
  )
};

export default Section;